import { Router, LocationProvider } from "@reach/router"
import { Home } from './pages'
import { Header } from "./components"


export default function App() {
	return (
		<div id="App">
			<LocationProvider>
				<Header />
				<Router primary={false} id="pageWrap" style={{ width: '100%' }}>
					<Home path="/" />
				</Router>
			</LocationProvider>
		</div>
	)
}
