import { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import axios from 'axios'
import { credentials } from '../../config'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCube } from 'swiper/modules';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap'
import 'swiper/css/effect-cube';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Youtube = () => {
    const ref = useRef(null);
    const [videos, setVideos] = useState([])
    const loadData = async () => {
        const ytResponse = await axios.get(
            `https://youtube.googleapis.com/youtube/v3/playlistItems?part=contentDetails&playlistId=${credentials.playlistId}&key=${credentials.youtubeApiKey}`,
            {
                headers: {
                    'Accept': 'application/json'
                }
            })

        // @ts-expect-error
        const videoIds = ytResponse.data.items.reduce((acc, item) => {
            acc = [...acc, item.contentDetails.videoId]
            return acc;
        }, [])

        setVideos(videoIds.reverse());
    }

    useEffect(() => {
        loadData()
    }, [])
    return (
        <div className={styles.youtubeWrapper} ref={ref} id='youtubeWrapper' >
            <p>Fiecare seară este diferită</p>
            <Swiper
                effect={'cube'}
                cubeEffect={{
                    shadow: true,
                    slideShadows: true,
                    shadowOffset: 20,
                    shadowScale: 0.94,
                }}


                spaceBetween={0}
                slidesPerView={1}
                autoplay
                className={styles.slider}
                modules={[Navigation, EffectCube]}
                navigation={true}
                pagination={{
                    clickable: true,
                }}
            >
                {videos.map((video, index) =>
                    <SwiperSlide className={styles.slide} key={`video-${index}`}>
                        <iframe style={{ minHeight: '300px' }} className={styles.video} width="100%" height="100%" src={`https://www.youtube.com/embed/${video}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                    </SwiperSlide>
                )}
            </Swiper>
            <Controller>
                <Scene
                    triggerElement="#youtubeWrapper"
                    duration={'800'}
                >
                    {(progress: number) => (
                        <Tween
                            from={{
                                left: 0,
                                // opacity: 1
                            }}
                            to={{
                                left: '100%',
                                // opacity: 0
                            }}
                            ease="Strong.easeOut"
                            totalProgress={progress}
                            paused
                            className={styles.overlay}
                        >
                            <div className={styles.overlay} />
                        </Tween>
                    )}
                </Scene>
            </Controller>
        </div>
    )
}

export default Youtube;
