import { Link, useLocation } from "@reach/router";
import styles from './styles.module.scss'
import logo from '../../assets/logo.webp'

const Header = () => {
	const location = useLocation();
	if (location.pathname === '/') {
		return (
			<div className={styles.header}>
				<a href='#home'><img src={logo} alt="logo" height={"2.5rem"} /></a>
			</div>
		)
	}
	return (
		<div className={styles.header}>
			<Link to="/">
				<img src={logo} alt="logo" height={"2.5rem"} />
			</Link>
		</div>
	)
}

export default Header