import { RouteComponentProps } from '@reach/router'
import { useState } from 'react'
import { useIsMobile } from '../../hooks'
import { Button, Image, InfoBox, Footer, Layout, Lighting, Loader, NewsLetter, TextEffect, Video, VideoBig, Youtube } from '../../components'
import styles from './styles.module.scss'
import { FunctionComponent } from 'react'
import after from '../../assets/topVideo.mp4';
import movie from '../../assets/bgVideo.mp4';
import perc from '../../assets/icons/percent.svg'
import city from '../../assets/icons/city.svg'
import user from '../../assets/icons/user.svg'
import music from '../../assets/icons/music.svg'
import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap'

import p16000 from '../../assets/images/1-1600.webp';
import p26000 from '../../assets/images/p2-1600.webp';
import p36000 from '../../assets/images/p3-1600.webp';
import p46000 from '../../assets/images/p4-1600.webp';
import p56000 from '../../assets/images/p5-1600.webp';
import p66000 from '../../assets/images/p6-1600.webp';
import p76000 from '../../assets/images/p7-1600.webp';

const ANIM_DURATION = 1100

const Home: FunctionComponent<RouteComponentProps> = () => {
	const [loading, setLoading] = useState(true)
	const isMobile = useIsMobile()
	return (
		<Layout isHomePage>
			{loading && <Loader />}
			<div className={styles.videoWrapper}>
				<Video id="home" video={after} setLoading={setLoading} />
				<div className={styles.textWrapper}>
					<p>Biletele pentru cel mai shukar turneu al anului sunt acum disponibile!</p>
					<span>Nu rata ocazia de a fi parte dintr-o experiență memorabilă și captivantă.</span>
					<Button
						id="newsletter"
						customStyles={{ margin: '4vh 2vw' }}
						label="Bilete"
						isUrl
						url="https://bilete.maneliada.ro"
					/>
				</div>
				<div className={styles.scrollIcon}>
					<div className={styles.scrollDot} />
				</div>
			</div>
			<TextEffect text="Mai mult decât o petrecere, trăiește momentul și bucură-te la maxim de Tzanca și Fred Again.. în același timp" />
			<div className={styles.animBackground} id='trigger'>
				<Lighting isMobile={isMobile} />
				<Controller>
					<Scene
						triggerElement="#trigger"
						duration={isMobile ? 400 : ANIM_DURATION}
					>
						{(progress: number) => (
							<Tween
								from={{
									scale: 0,
									opacity: 0
								}}
								to={{
									scale: 1,
									opacity: 1,
									left: isMobile ? "25vw" : "20vw",
									top: isMobile ? "10vh" : '30vh'
								}}
								ease="Strong.easeOut"
								totalProgress={progress}
								paused
								className={styles.sold}
							>
								<div className={styles.sold} >
									<InfoBox reverse title={"80%"} subtitle={"sold out"} icon={perc} />
								</div>
							</Tween>
						)}
					</Scene>
					<Scene
						triggerElement="#trigger"
						duration={isMobile ? 400 : ANIM_DURATION}
					>
						{(progress: number) => (
							<Tween
								from={{
									scale: 0,
									opacity: 0
								}}
								to={{
									scale: 1,
									opacity: 1,
									left: "70vw",
									top: isMobile ? "20vh" : '30vh'
								}}
								ease="Strong.easeOut"
								totalProgress={progress}
								paused
								className={styles.sold}
							>
								<div className={styles.sold} >
									<InfoBox title={"105 ore"} subtitle={'de libertate'} icon={music} />
								</div>
							</Tween>
						)}
					</Scene>
					<Scene
						triggerElement="#trigger"
						duration={isMobile ? 400 : ANIM_DURATION}
					>
						{(progress: number) => (
							<Tween
								from={{
									scale: 0,
									opacity: 0
								}}
								to={{
									scale: 1,
									opacity: 1,
									left: isMobile ? "25vw" : "30vw",
									top: isMobile ? "40vh" : '90vh'
								}}
								ease="Strong.easeOut"
								totalProgress={progress}
								paused
								className={styles.sold}
							>
								<div className={styles.sold} >
									<InfoBox reverse title={"14000"} subtitle={'de petrecăreți'} icon={user} />
								</div>
							</Tween>
						)}
					</Scene>
					<Scene
						triggerElement="#trigger"
						duration={isMobile ? 400 : ANIM_DURATION}
					>
						{(progress: number) => (
							<Tween
								from={{
									scale: 0,
									opacity: 0
								}}
								to={{
									scale: 1,
									opacity: 1,
									left: isMobile ? "70vw" : "80vw",
									top: isMobile ? "50vh" : '90vh'
								}}
								ease="Strong.easeOut"
								totalProgress={progress}
								paused
								className={styles.sold}
							>
								<div className={styles.sold} >
									<InfoBox title={"6 orașe"} subtitle={'vizitate'} icon={city} />
								</div>
							</Tween>
						)}
					</Scene>
				</Controller>
			</div>
			<div className={styles.ticketsWrapper}>
				<p>Cumpără bilete acum!</p>
				<span>Ca iubirea noastră să prindă 300 la ora</span>
				<Button
					id="bilete"
					customStyles={{ margin: '4vh 2vw' }}
					label="Bilete"
					isUrl
					url="https://bilete.maneliada.ro"
				/>
			</div>
			<Youtube />
			<VideoBig id="home" video={movie} />
			<div className={styles.picturesWrapper}>
				<p className={styles.title}>Nu te-am convins încă?</p>
				<p className={styles.subTitle}>Hai să îți arătăm cum ne distrăm!</p>
				<Image
					id="image1"
					image={p16000}
					text={'“N-ai cum să nu-ți placă Maneliada”'}
					author={'Ionuț Bo$$u (Iași)'}
				/>
				<Image
					id="image2"
					image={p26000}
					text='“Maneliada e perfectă așa cum este”'
					author='Horia Fantastik (Sibiu)'
				/>
				<Image
					id="image3"
					image={p36000}
					text='"Mamă ce tari sunt mascații de la Electro Manele, mi-au schimbat viața"'
					author='Bogdan (Bucuresti)'
					portrait
					reverse
				/>
				<p className={styles.title}>Haide sus pe mese</p>
				<Image
					id="image4"
					image={p46000}
					text='"Am participat, am fost, mai merg”'
					author='Zâna Paula (Cluj-Napoca)'
					portrait
				/>
				<p className={styles.title}>Cheful chefurilor</p>
				<Image
					id="image5"
					image={p56000}
					text='“Sunteți șmekeroși 😎”'
					author='Șefa Andreea (București)'
				/>
				<Image
					id="image6"
					image={p66000}
					text='“Atmosfera este una de un festival de muzică. Îmi place vibe-ul pe care îl oferiți oamenilor.”'
					author='Luminița Regina Transilvaniei (Cluj-Napoca)'
					portrait
					reverse
				/>
				<p className={styles.title}>Am dat moda în țară</p>
				<Image
					id="image7"
					image={p76000}
					text='“Mi-a plăcut tot, vă ador!!”'
					author='Andreea VTM (Timișoara)'
				/>
			</div>
			<NewsLetter />
			<Footer />
		</Layout>
	)
}

export default Home
